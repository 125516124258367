import book from '../../imgs/svgs/book.svg'
import time from '../../imgs/svgs/time.svg'
import balance from '../../imgs/svgs/balance.svg'
import hat from '../../imgs/svgs/hat.svg'
import hourglass from '../../imgs/svgs/hourglass.svg'
import check from '../../imgs/svgs/check.svg'

import './styles.css'

function Competencias() {
    return (
        <div id="compentencias-anchor" className="tela-3-main">
            <div className="centerContainer">
                <h1>
                    Ao adquirir o Programa de Capacitação em Licitações Públicas
                    e Contratos Administrativos, nós garantimos:
                </h1>
                <div className="all-svgs-box">
                    <div className="svg-text-box">
                        <img alt="balança" src={balance} />
                        <span>
                            <strong className="strong">
                                Um programa de capacitação
                            </strong>{' '}
                            <br />
                            direcionado à atuação de profissionais e empresas
                            que atuam na área de licitações públicas
                        </span>
                    </div>

                    <div className="svg-text-box">
                        <img alt="chapeu" src={hat} />
                        <span>
                            <strong className="strong">
                                Material construído por profissionais <br />
                            </strong>{' '}
                            com alta qualificação e especialização em Direito
                            Administrativo, formados nas melhores universidades
                            do país
                        </span>
                    </div>

                    <div className="svg-text-box">
                        <img alt="relogio" src={time} />
                        <span>
                            {' '}
                            <strong className="strong">
                                15 horas de conteúdo{' '}
                            </strong>{' '}
                            <br />
                            original e atualizado
                        </span>
                    </div>

                    <div className="svg-text-box">
                        <img alt="livro" src={book} />
                        <span>
                            <strong className="strong">12 meses</strong>
                            <br />
                            de acesso ao conteúdo
                        </span>
                    </div>

                    <div className="svg-text-box">
                        <img alt="fogo" src={hourglass} />
                        <span>
                            <strong className="strong">
                                Garantia incondicional
                            </strong>{' '}
                            <br />
                            de 7 dias
                        </span>
                    </div>

                    <div className="svg-text-box">
                        <img alt="balança" src={check} />
                        <span>
                            <strong className="strong">
                                Avaliações de desempenho
                            </strong>{' '}
                            <br />
                            individuais
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Competencias
