import './styles.css'

const FirstScreen = () => {
    return (
        <div id="firstScreen">
            <div className="gradient"></div>
            <span className="upperText"></span>
            <h1 className="title">
                Programa de Capacitação em Licitações Públicas e Contratos
                Administrativos
            </h1>
            <h2 className="subtitle">
                Adequado à Nova Lei de Licitações e Contratos Administrativos
                (Lei nº 14.133/2021)
            </h2>
            <button
                className="headerBtns btnMatricule"
                onClick={() => (window.location.href = '#investimento')}
            >
                COMECE AGORA
            </button>
        </div>
    )
}

export default FirstScreen
