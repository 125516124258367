import './styles.css'
import arrow from '../../imgs/arrow.svg'
import pagseguro from '../../imgs/pagseguro.png'

const Investimento = () => {
    const handleCheckOut = () => {
        window.location.href =
            'https://membros.schiefler.academy/checkout/GwgZvG'
    }

    return (
        <div id="investimento">
            <div className="centerContainer">
                <div className="boxCenter">
                    <div className="leftBox">
                        {frases.map((objeto, i) => (
                            <div key={i} className="arrow-box">
                                <img
                                    alt="seta"
                                    src={arrow}
                                    className="arrow"
                                ></img>
                                <span>{objeto.frase}</span>
                            </div>
                        ))}
                    </div>
                    <div id="#investimento" className="rightBox">
                        <h1>
                            Garanta o melhor curso de Licitações Públicas e
                            Contratos Administrativos do mercado!
                        </h1>
                        <div className="price-box">
                            <span className="txt-valor">
                                <span className="sale">
                                    <span className="investimento">
                                        Investimento:{' '}
                                    </span>
                                    <span className="old-value">R$ 997,00</span>
                                    <span className="discount">-50%</span>
                                </span>
                                <span>
                                    R$ 497,00{' '}
                                    <span className="txt-a-vista">
                                        em até 6x sem juros
                                    </span>
                                    {/* <span className="txt-parcelado">em até 6x sem juros</span> */}
                                </span>
                            </span>
                        </div>
                        <button onClick={handleCheckOut} className="btn-tela-4">
                            COMECE AGORA
                        </button>
                        <div className="payment-icons">
                            <img
                                onClick={handleCheckOut}
                                className="pagseguro icons"
                                src={pagseguro}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const frases = [
    { frase: 'Construído por profissionais com alta qualificação' },
    { frase: '15 horas de Conteúdo original e atualizado' },
    { frase: 'Material didático completo por escrito' },
    { frase: 'Avaliações de desempenho individuais' },
    { frase: 'Garantia incondicional de 7 dias' },
]

export default Investimento
