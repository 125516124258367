import logo from "./imgs/schiefler-academia-vermelho-e-azul.png";
import ssl from './imgs/ssl.png'


function Footer() {
    return (
        <div className="footer">
            <img alt="logo" src={logo}></img>
            <div className="footer-info">
                <span className="footer-field strong">Schiefler Academia</span>
                <span className="footer-field">RAZÃO SOCIAL: Gustavo Henrique Carvalho Schiefler 00987746979</span>
                <span className="footer-field">CNPJ: 23.827.808/0001-42.</span>
                <span className="footer-field">Todos os direitos reservados.</span>
            </div>
            <img alt='ssl' className="ssl" src={ssl} />
        </div>
    )
}

export default Footer