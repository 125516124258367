import './style/App.css'
import Header from './components/header/index.jsx'
import FirstScreen from './pages/firstScreen'
import Cards from './pages/cards'
import Investimento from './pages/investimento'
import VideoScreen from './pages/videoScreen'
import Imagens from './pages/imagens'
import Faq from './pages/faq'
import Competencias from './pages/competencias/Competencias'
import Footer from './Footer'
import ProvaSocial from './ProvaSocial'
import Cadastro from './pages/cadastro/Cadastro'

function App() {
    return (
        <div className="app">
            <Header />
            <FirstScreen />
            <Cards />
            <VideoScreen />
            <Competencias />
            <Imagens />
            <ProvaSocial />
            <Cadastro />
            <Investimento />
            <div className="centerContainer">
                <Faq />
            </div>
            <Footer />
        </div>
    )
}

export default App
