import logo from '../../imgs/schiefler-academia-vermelho-e-azul.png'
import './styles.css'

function Header() {
    const redirectMember = () => {
        window.location.href = 'https://membros.schiefler.academy'
    }
    return (
        <div id="header">
            <div className="menu">
                <img alt="logo schiefler academy" src={logo}></img>
                <div className="buttonsContainer">
                    <button
                        className="headerBtns btnMatricule"
                        onClick={() => (window.location.href = '#investimento')}
                    >
                        MATRICULE-SE JÁ
                    </button>
                    <button
                        className="headerBtns btnAluno"
                        onClick={redirectMember}
                    >
                        JÁ SOU ALUNO
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Header
