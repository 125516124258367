import { useState } from 'react'
import './styles.css'

function Faq() {
    const [selected, setSelected] = useState(null)

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    return (
        <div id="faq-anchor" className="faq-screen wrapper">
            <h1>Dúvidas frequentes</h1>
            <div className="accordion">
                {data.map((item, i) => (
                    <div key={i} className="item" onClick={() => toggle(i)}>
                        <div className="faqTitle">
                            <h2>{item.question}</h2>
                            <span>{selected === i ? '-' : '+'}</span>
                        </div>
                        <div
                            className={
                                selected === i ? 'content show' : 'content'
                            }
                        >
                            {item.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const data = [
    {
        question: 'Que tipo de conteúdo irei receber?',
        answer: 'O assinante do Programa de Capacitação em Licitações Públicas e Contratos Administrativos terá acesso aos módulos indicados nesta proposta, que contemplam não só videoaulas de ensino, como também ebook com conteúdo respectivo, exercícios de fixação ao final de cada módulo e certificado de conclusão ao final de cada nível.',
    },
    {
        question: 'Quem é o coordenador?',
        answer: 'Gustavo Henrique Carvalho Schiefler: Advogado. Doutor em Direito do Estado pela Universidade de São Paulo (USP). Mestre e graduado em Direito pela Universidade Federal de Santa Catarina (UFSC). Educação Executiva pela Harvard Law School (Program on Negotiation). Pesquisador Visitante no Max-Planck-Institut für ausländisches und internationales Privatrecht, em Hamburgo (Alemanha). Integra a equipe de professores e consultores externos da Zênite Informação e Consultoria S/A. Autor dos livros "Diálogos Público-Privados" (2018) e "Procedimento de Manifestação de Interesse - PMI (2014). Coautor do livro "Contratação de Serviços Técnicos Especializados por Inexigibilidade de Licitação Pública" (2015). Autor de dezenas de artigos científicos na área de licitações e contratações públicas.',
    },
    {
        question: 'Como foi construído este Programa?',
        answer: 'Este Programa foi construído por profissionais com alta qualificação e especialização em Direito Administrativo, formados nas melhores universidades de Direito do país, com larga experiência na atuação consultiva e contenciosa em causas envolvendo licitações e contratos administrativos.',
    },
    {
        question: 'Para quem esse curso é indicado?',
        answer: 'O Programa de Capacitação em Licitações Públicas e Contratos Administrativos tem a missão de capacitar profissionais de mercado que atuam em licitações públicas e contratos administrativos. Portanto, é recomendado para todos que tenham interesse em trabalhar com este mercado, seja como empresário, advogado, consultor, equipe administrativa ou comercial de licitante, ou ainda como servidor da própria Administração Pública. ',
    },
    {
        question: 'Por quanto tempo terei acesso ao curso?',
        answer: 'Uma vez contratado o curso, você terá acesso a todas as aulas pelo período de 12 meses, assim você poderá revisar conteúdos já estudados e adotar a rotina de estudos que mais se adequa ao seu perfil.',
    },
    {
        question: 'O curso é online?',
        answer: 'Sim! O Programa de Capacitação em Licitações Públicas e Contratos Administrativos da Schiefler Academia é 100% online, sendo as aulas assíncronas e a quantidade de acessos ilimitada pelo período contratado.',
    },
    {
        question: 'E se eu não gostar do produto?',
        answer: 'Nós temos uma garantia incondicional. Caso você não goste do produto, entre em contato pelo e-mail atendimento@schiefler.academy, em até 7 dias após a compra, e nós devolveremos seu dinheiro.',
    },
    {
        question:
            'Consigo interagir com os professores para tirar minhas dúvidas?',
        answer: 'Sim! Em nossa plataforma, cada aula conta com um espaço próprio, logo abaixo do vídeo, para envio de comentários e dúvidas por escrito, todas as suas dúvidas serão respondidas por nossos professores.',
    },
]

export default Faq
