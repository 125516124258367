import degree from './imgs/svgs/degree.svg'

function ProvaSocial() {
    const handleCheckOut = () => {
        window.location.href =
            'https://membros.schiefler.academy/checkout/GwgZvG'
    }
    return (
        <div className="prova-social-main">
            <div className="centerContainer">
                <div className="certificado-box">
                    <div className="flex-col">
                        <img
                            alt="chapéu de formatura"
                            className="degree-svg"
                            src={degree}
                        />
                        <p>
                            {
                                'Ao final de cada módulo, os participantes aprovados receberão um certificado de conclusão assinado pelo coordenador do Programa de Capacitação e individualizado para cada aluno e com a respectiva data da conclusão'
                            }
                        </p>
                    </div>
                </div>
            </div>
            <a href="#investimento" className="btn-quero-adquirir">
                Quero Adquirir
            </a>
        </div>
    )
}

export default ProvaSocial
