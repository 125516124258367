import './styles.css'
import { ModuloComponent } from '../../components/moduloComponent'
import { useState } from 'react'
import { Carousel } from 'react-bootstrap'

function Cards() {
    const [selected, setSelected] = useState(null)
    return (
        <div id="cards">
            <div className="modulos-box biggerScreen">
                {moduloBasico.map((item, i) => (
                    <ModuloComponent
                        key={i}
                        item={item}
                        i={i}
                        selected={selected}
                        setSelected={setSelected}
                    />
                ))}
            </div>
            <Carousel className="smallerScreen">
                {moduloBasico.map((item, i) => (
                    <Carousel.Item>
                        <ModuloComponent
                            key={i}
                            item={item}
                            i={i}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

const moduloBasico = [
    {
        title: `Módulo 1`,
        subtitle: `Como funcionam as licitações no Brasil?`,
        content: [
            'Por que licitamos? O propósito e o fundamento constitucional das contratações públicas no Brasil.',
            'Regra de ouro das licitações públicas: compreendendo a essência das regras de qualquer certame.',
            'Estatísticas do mercado público: as contratações diretas são exceção ou regra?',
            'Estatísticas do mercado público: quanto vale 12% do PIB nacional?',
            'As principais leis sobre licitação pública e contratos administrativos: navegando entre diferentes normas.',
            'Entendendo o processo licitatório: as etapas do certame.',
            'Modelagens mais comuns de licitação pública: o pregão, a concorrência e a ausência de modalidade licitatória nas empresas estatais.',
            'Janela de oportunidade: por que vivemos um ano extraordinário para as licitações públicas?',
        ],
    },

    {
        title: `Módulo 2`,
        subtitle: `Raio-X da licitação pública: do planejamento do edital até a homologação e celebração do contrato.`,
        content: [
            'O que mudou com a Nova Lei de Licitações (Lei nº 14.133/2021)?',
            'Como funciona a etapa preparatória de uma licitação pública?',
            'As comunicações legítimas da Administração com os particulares durante a preparação de um edital: o levantamento de mercado e a pesquisa de preços.',
            'Compreenda a lógica de um edital de licitação e de seus anexos.',
            `Pregão - Aspectos gerais: vantagens e desvantagens, legislação aplicável, bens e serviços comuns e obrigatoriedade do pregão. Etapas: Credenciamento no sistema ou na sessão, envio das propostas, abertura das propostas, classificação, disputa de lances, negociação, habilitação, recursos e homologação. Diferenças procedimentais da Nova Lei de Licitações em relação à Lei nº 10.520/2002.`,
            `Concorrência - Aspectos gerais. Etapas: credenciamento no sistema ou na sessão, envio das propostas, abertura das propostas, classificação, disputa de lances, negociação, habilitação, recursos e homologação. Hipóteses de revogação e anulação. Diferenças da Nova Lei de Licitações em relação à Lei nº 8.666/1993.`,
            'Revogação e anulação do certame licitatório.',
        ],
    },

    {
        title: 'Módulo 3',
        subtitle: `Como uma empresa pode se tornar mais competitiva nas licitações?`,
        content: [
            'Sessão de licitação: noções gerais e dicas de preparação.',
            'O que são, qual o entendimento dos tribunais e como funcionam os robôs de lances no pregão eletrônico?',
            'Participação em consórcio e as subcontratações.',
            'Recomendações para a fase de negociação na licitação.',
            'Análise de documentação de concorrentes e os problemas mais comuns que levam à inabilitação e à desclassificação de licitantes.',
            'Fase recursal: desde a motivação da intenção de recorrer até a obtenção dos documentos da licitação para análise.',
            'Fase recursal: contagem dos prazos para recurso, para contrarrazões e para a decisão.',
            'Fase recursal: como redigir e como fundamentar a peça de recurso ou de contrarrazões (onde encontrar jurisprudência e doutrina – e, claro, as normas da lei).',
            'Direito de petição: o que é e como pode ser utilizado para provocar a correção de atos praticados pela Administração (autotutela).',
        ],
    },
]

export default Cards
