import { Form } from '../../components/Form'
import mosaico from '../../imgs/mosaico.png'

import './styles.css'

function Cadastro() {
    return (
        <div className="cadastro-main">
            <form className="cadastro-form">
                <img src={mosaico} className="background" />
                <div className="inputs-box">
                    <h1>
                        Cadastre-se para receber gratuitamente uma de nossas
                        aulas
                    </h1>
                    <Form></Form>
                </div>
            </form>
        </div>
    )
}

export default Cadastro
