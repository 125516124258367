import { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import ft1 from '../../imgs/advs/ft1.jpg'
import ft2 from '../../imgs/advs/ft2.jpg'
import ft3 from '../../imgs/advs/ft3.jpg'
import ft4 from '../../imgs/advs/ft4.jpg'
import ft5 from '../../imgs/advs/ft5.jpg'

import './styles.css'

function Imagens() {
    const [index, setIndex] = useState(0)

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex)
    }
    return (
        <div id="imagens">
            <h1 className="mainTitle">Conheça nosso time de especialistas</h1>
            <Carousel className="smallerScreen">
                {adv.map((advogado, i) => (
                    <Carousel.Item key={i}>
                        <div className="carouselItem">
                            <div className="eachAdvogado">
                                <img className="advImg" src={advogado.img} />
                                <div className="textContainer">
                                    <span className="advName">
                                        {advogado.name}
                                    </span>
                                    <span className="advInfo">
                                        {advogado.info}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Carousel className="biggerScreen">
                <Carousel.Item>
                    <div className="carouselItem">
                        <div className="eachAdvogado">
                            <img className="advImg" src={adv[0].img} />
                            <div className="textContainer">
                                <span className="advName">{adv[0].name}</span>
                                <span className="advInfo">{adv[0].info}</span>
                            </div>
                        </div>
                        <div className="eachAdvogado">
                            <img className="advImg" src={adv[1].img} />
                            <div className="textContainer">
                                <span className="advName">{adv[1].name}</span>
                                <span className="advInfo">{adv[1].info}</span>
                            </div>
                        </div>
                        <div className="eachAdvogado">
                            <img className="advImg" src={adv[2].img} />
                            <div className="textContainer">
                                <span className="advName">{adv[2].name}</span>
                                <span className="advInfo">{adv[2].info}</span>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carouselItem">
                        <div className="eachAdvogado">
                            <img className="advImg" src={adv[3].img} />
                            <div className="textContainer">
                                <span className="advName">{adv[3].name}</span>
                                <span className="advInfo">{adv[3].info}</span>
                            </div>
                        </div>
                        <div className="eachAdvogado">
                            <img className="advImg" src={adv[4].img} />
                            <div className="textContainer">
                                <span className="advName">{adv[4].name}</span>
                                <span className="advInfo">{adv[4].info}</span>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

const adv = [
    {
        name: 'Gustavo Schiefler',
        info: `Advogado. Doutor em Direito do Estado pela Universidade de São Paulo (USP). Mestre e Bacharel em Direito pela Universidade Federal de Santa Catarina (UFSC). Professor de pós-graduação em Direito Administrativo do Instituto de Direito Público (IDP), em Brasília. Professor e coordenador da Schiefler Academia.`,
        img: ft1,
    },
    {
        name: 'Eduardo Schiefler',
        info: `Advogado. Mestre em Direito pela Universidade de Brasília (UnB). Graduado em Direito pela Universidade Federal de Santa Catarina (UFSC). Professor de cursos de especialização em licitações e contratos administrativos. Professor da Schiefler Academia.`,
        img: ft2,
    },
    {
        name: 'Giovanna Gamba',
        info: `
        Advogada. Mestra em Direito do Estado pela Universidade de São Paulo (USP). Graduada em Direito pela Universidade Federal de Santa Catarina (UFSC). Professora da Schiefler Academia.`,
        img: ft3,
    },
    {
        name: 'Murillo Preve Cardoso',
        info: `
        Advogado. Mestre em Direito do Estado pela Universidade Federal do Paraná (UFPR). Graduado em Direito pela Universidade Federal de Santa Catarina (UFSC). Professor da Schiefler Academia.`,
        img: ft4,
    },

    {
        name: 'Walter Marquezan Augusto',
        info: `Advogado. Doutor em Direito Econômico pela Universidade de São Paulo (USP). Mestre em Teoria, História e Filosofia do Direito pela Universidade Federal de Santa Catarina (UFSC). Graduado em Direito pela Universidade Federal do Rio Grande (FURG). Professor da Schiefler Academia.`,
        img: ft5,
    },
]

export default Imagens
