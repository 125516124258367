import './styles.css'

export const Form = () => {
    return (
        <div
            id="mauticform_wrapper_formlandingpagepx"
            class="mauticform_wrapper"
        >
            <form
                target="_blank"
                autocomplete="false"
                role="form"
                method="post"
                action="https://marketing.schiefler.academy/form/submit?formId=1"
                id="mauticform_formlandingpagepx"
                data-mautic-form="formlandingpagepx"
                enctype="multipart/form-data"
            >
                <div
                    class="mauticform-error"
                    id="mauticform_formlandingpagepx_error"
                ></div>
                <div
                    class="mauticform-message"
                    id="mauticform_formlandingpagepx_message"
                ></div>
                <div class="mauticform-innerform">
                    <div
                        class="mauticform-page-wrapper mauticform-page-1"
                        data-mautic-form-page="1"
                    >
                        <div
                            id="mauticform_formlandingpagepx_nome"
                            data-validate="nome"
                            data-validation-type="text"
                            class="mauticform-row mauticform-text mauticform-field-1 mauticform-required"
                        >
                            <input
                                id="mauticform_input_formlandingpagepx_nome"
                                name="mauticform[nome]"
                                defaultValue=""
                                class="mauticform-input"
                                type="text"
                                placeholder="Nome completo"
                            />
                            <span class="mauticform-errormsg">
                                Isso é obrigatório.
                            </span>
                        </div>

                        <div
                            id="mauticform_formlandingpagepx_email"
                            data-validate="email"
                            data-validation-type="email"
                            class="mauticform-row mauticform-email mauticform-field-2 mauticform-required"
                        >
                            <input
                                id="mauticform_input_formlandingpagepx_email"
                                name="mauticform[email]"
                                defaultValue=""
                                class="mauticform-input"
                                type="email"
                                placeholder="Seu email"
                            />
                            <span class="mauticform-errormsg">
                                Isso é obrigatório.
                            </span>
                        </div>

                        <div
                            id="mauticform_formlandingpagepx_submit"
                            class="mauticform-row mauticform-button-wrapper mauticform-field-3"
                        >
                            <button
                                type="submit"
                                name="mauticform[submit]"
                                id="mauticform_input_formlandingpagepx_submit"
                                defaultValue=""
                                class="mauticform-button btn btn-default"
                            >
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>

                <input
                    type="hidden"
                    name="mauticform[formId]"
                    id="mauticform_formlandingpagepx_id"
                    defaultValue="1"
                />
                <input
                    type="hidden"
                    name="mauticform[return]"
                    id="mauticform_formlandingpagepx_return"
                    defaultValue=""
                />
                <input
                    type="hidden"
                    name="mauticform[formName]"
                    id="mauticform_formlandingpagepx_name"
                    defaultValue="formlandingpagepx"
                />
            </form>
        </div>
    )
}
