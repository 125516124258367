export const ModuloComponent = ({ item, i }) => {
    return (
        <div className="modulos">
            <span className="content-title">{item.title}</span>
            <div name="content" className="contentModulos">
                <span className="subtitle">{item.subtitle}</span>
                {item.content.map((topic, index) => (
                    <div key={index} className="topic">
                        <span className="material-symbols-outlined check-circle">
                            check_circle
                        </span>
                        <span>{topic}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
