import './styles.css'
function VideoScreen() {
    return (
        <div id="videoScreen">
            <h1 className="videoTitle">SAIBA MAIS ↓</h1>
            <div className="video-container">
                <iframe
                    title="vimeo-player"
                    className="iframe-video"
                    allow="autoplay; fullscreen"
                    src="https://player.vimeo.com/video/781921425?h=4dc03eb50d"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
            <a href="#investimento" className="btn-anchor">
                MATRICULE-SE JÁ
            </a>
        </div>
    )
}

export default VideoScreen
